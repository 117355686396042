import React, { useState } from 'react';

import './accordion.scss'

const Accordion = ( {title, content }) => {
    const [isActive, setIsActive] = useState(false)

    return (
        <div className='accordion-wrapper'>
            <div className="accordion-item">
                <div
                    className={isActive ? 'accordion-title-active' : 'accordion-title'}
                    onClick={() => setIsActive(!isActive)}
                >
                    <div>{title}</div>
                    <div>{isActive ? '-' : '+'}</div>
                </div>
                {isActive && <div className="accordion-content">{content}</div>}
            </div>
        </div>

    )
}

export default Accordion