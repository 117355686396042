export const pricingData = [
  {
    name: 'free',
    title: 'Free',
    description: "For curious Ecommerce companies wanting to test an interactive shopping experience",
    price: {
        annual: {
            sweden: 'Free',
            eur: 'Free'
        },
        monthly: {
            sweden: 'Free',
            eur: 'Free'
        }
    },
    tableFeatures: [
       {
            id: 1,
            name: '1 user',
            tooltip: 'Invite people to your Qiwio account and collaborate on your video projects.'
        },
        {
            id: 2,
            name: '2 interactive videos',
            tooltip: 'Create up to 2 interactive videos at a time.'
        },
        {
            id: 3,
            name: '10 interactive images',
            tooltip: 'Create up to 10 interactive images at a time.'
        },
        {
            id: 4,
            name: 'Standard feature set',
            tooltip: 'Standard features included. Details about what features are included can be found below.'
        },
    ],
    detailFeatures:  [
        {
            id: 1,
            name: '1 user',
            tooltip: 'Invite people to your Qiwio account and collaborate on your video projects.'
        },
        {
            id: 2,
            name: '2 interactive videos',
            tooltip: 'Create up to 2 interactive videos at a time.'
        },
        {
            id: 3,
            name: '10 interactive images',
            tooltip: 'Create up to 10 interactive images at a time.'
        },
        {
            id: 4,
            name: 'Advanced interactive video editor',
            tooltip: "One of the world's most advanced interactive video editors at your fingertips. Can you handle that?"
        },
        {
            id: 5,
            name: 'Standard feature set',
            tooltip: 'Standard features included.'
        },
        {
            id: 6,
            name: 'Hotspots',
            tooltip: "Hotspots highlight products within the video for better conversions."
        },
        {
            id: 7,
            name: 'Custom hotspot creator',
            tooltip: "Custom hotspot creator allows you to upload your own SVG file and manipulate color, hover state and animations. Start to customize your Qiwio video however you wish."
        },
        {
            id: 8,
            name: 'Video funnels',
            tooltip: "Create truly interactive video experiences by changing which video is shown depending on the viewers interests for a unique experience and increase engagement and conversions through video."
        },
        {
            id: 9,
            name: 'Time triggers',
            tooltip: "Automatically jump to certain time frames based on the viewers actions."
        },
        {
            id: 10,
            name: 'Subtitles',
            tooltip: "Add subtitles to the video player using SRT files so viewers can toggle subtitles on/off on your videos."
        },
        {
            id: 11,
            name: 'Data metrics dashboard',
            tooltip: "Detailed video performance metrics, allowing you to optimize your video based on results in real time."
        },
        {
            id: 12,
            name: 'Social media integrations',
            tooltip: "Share your videos to Facebook, LinkedIn and Youtube to capture more engagement from social media."
        },
        {
            id: 13,
            name: 'Project assistance: Available to buy',
            tooltip: "Get expert help from the Qiwio creative team, allow us to jump on your project to help you push the boundaries of what's possible with interactive video and shoppable video and unleash the true power of the Qiwio studio platform."
        },
        {
            id: 14,
            name: '1:1 training sessions: Available to buy',
            tooltip: "Our customer success team will hold a training workshop for you and your team to teach you everything you need to know about the Qiwio interactive studio to help your team unleash the full power of interactive video."
        },
        {
            id: 15,
            name: 'Self service support',
            tooltip: "Gain access to our in-depth knowledge base to help you get the most out of Qiwio."
        },
    ],
  }, {
    name: 'standard',
    title: 'Standard',
    description: "For eCommerce companies getting started with interactive video shopping experiences",
    price: {
        annual: {
            sweden: '900 SEK',
            eur: '90 EUR'
        },
        monthly: {
            sweden: '1 000 SEK',
            eur: '100 EUR'
        }
    },
    tableFeatures: [
       {
            id: 1,
            name: '3 users',
            tooltip: 'Invite people to your Qiwio account and collaborate on your video projects.'
        },
        {
            id: 2,
            name: '2 interactive videos',
            tooltip: 'Create up to 2 interactive videos at a time.'
        },
        {
            id: 3,
            name: '10 interactive images',
            tooltip: 'Create up to 10 interactive images at a time.'
        },
        {
            id: 4,
            name: 'Standard feature set',
            tooltip: 'Standard features included. Details about what features are included can be found below.'
        },
        {
            id: 5,
            name: 'Standard customer support',
            tooltip: "Recieve world-class customer support on weekdays via our support ticketing system."
        },
    ],
    detailFeatures:  [
        {
            id: 1,
            name: '3 users',
            tooltip: 'Invite people to your Qiwio account and collaborate on your video projects.'
        },
        {
            id: 2,
            name: '2 interactive videos',
            tooltip: 'Create up to 2 interactive videos at a time.'
        },
        {
            id: 3,
            name: '10 interactive images',
            tooltip: 'Create up to 10 interactive images at a time.'
        },
        {
            id: 4,
            name: 'Advanced interactive video editor',
            tooltip: "One of the world's most advanced interactive video editors at your fingertips. Can you handle that?"
        },
        {
            id: 5,
            name: 'Standard feature set',
            tooltip: 'Standard features included.'
        },
        {
            id: 6,
            name: 'Hotspots',
            tooltip: "Hotspots highlight products within the video for better conversions."
        },
        {
            id: 7,
            name: 'Custom hotspot creator',
            tooltip: "Custom hotspot creator allows you to upload your own SVG file and manipulate color, hover state and animations. Start to customize your Qiwio video however you wish."
        },
        {
            id: 8,
            name: 'Video funnels',
            tooltip: "Create truly interactive video experiences by changing which video is shown depending on the viewers interests for a unique experience and increase engagement and conversions through video."
        },
        {
            id: 9,
            name: 'Time triggers',
            tooltip: "Automatically jump to certain time frames based on the viewers actions."
        },
        {
            id: 10,
            name: 'Subtitles',
            tooltip: "Add subtitles to the video player using SRT files so viewers can toggle subtitles on/off on your videos."
        },
        {
            id: 11,
            name: 'Data metrics dashboard',
            tooltip: "Detailed video performance metrics, allowing you to optimize your video based on results in real time."
        },
        {
            id: 12,
            name: 'Social media integrations',
            tooltip: "Share your videos to Facebook, LinkedIn and Youtube to capture more engagement from social media."
        },
        {
            id: 13,
            name: 'Project assistance: Available to buy',
            tooltip: "Get expert help from the Qiwio creative team, allow us to jump on your project to help you push the boundaries of what's possible with interactive video and shoppable video and unleash the true power of the Qiwio studio platform."
        },
        {
            id: 14,
            name: '1:1 training sessions: Available to buy',
            tooltip: "Our customer success team will hold a training workshop for you and your team to teach you everything you need to know about the Qiwio interactive studio to help your team unleash the full power of interactive video."
        },
        {
            id: 15,
            name: 'Self service support',
            tooltip: "Gain access to our in-depth knowledge base to help you get the most out of Qiwio."
        },
        {
            id: 16,
            name: 'Standard customer support',
            tooltip: "Contact our support team via our ticketing system and get world class support via email."
        },
    ],
  },
  {
    name: 'plus',
    title: 'Plus',
    description: "For growing eCommerce companies looking to harness the power of interactive video shopping",
    price: {
        annual: {
            sweden: '4 500 SEK',
            eur: '450 EUR'
        },
        monthly: {
            sweden: '5 000 SEK',
            eur: '500 EUR'
        }
    },
     tableFeatures: [
       {
            id: 1,
            name: '10 users',
            tooltip: 'Invite people to your Qiwio account and collaborate on your video projects.'
        },
        {
            id: 2,
            name: 'Unlimited interactive videos',
            tooltip: 'Create as many interactive videos as you want. No, really, as many as your heart desires.'
        },
        {
            id: 3,
            name: 'Unlimited interactive images',
            tooltip: 'Create as many interactive images as you want. No, really, as many as your heart desires.'
        },
        {
            id: 4,
            name: 'Plus feature set',
            tooltip: 'Plus features included. Details about what features are included can be found below.'
        },
        {
            id: 5,
            name: 'Standard customer support',
            tooltip: "Recieve world-class customer support on weekdays via our support ticketing system."
        },
    ],
   detailFeatures:  [
        {
            id: 1,
            name: '10 users',
            tooltip: 'Invite people to your Qiwio account and collaborate on your video projects.'
        },
        {
            id: 2,
            name: 'Unlimited interactive videos',
            tooltip: 'Create as many interactive videos as you want. No, really, as many as your heart desires.'
        },
        {
            id: 3,
            name: 'Unlimited interactive images',
            tooltip: 'Create as many interactive images as you want. No, really, as many as your heart desires.'
        },
        {
            id: 4,
            name: 'Advanced interactive video editor',
            tooltip: "One of the world's most advanced interactive video editors at your fingertips. Can you handle that?"
        },
        {
            id: 5,
            name: 'Plus feature set',
            tooltip: 'Plus features included.'
        },
        {
            id: 6,
            name: 'Hotspots',
            tooltip: "Hotspots that highlight products within the video for better conversions."
        },
        {
            id: 7,
            name: 'Custom hotspot creator',
            tooltip: "Custom hotspot creator allows you to upload your own SVG file and manipulate color, hover state and animations. Start to customize your Qiwio video however you wish."
        },
        {
            id: 8,
            name: 'Video funnels',
            tooltip: "Create truly interactive video experiences by changing which video is shown depending on the viewers interests for a unique experience and increase engagement and conversions through video."
        },
        {
            id: 9,
            name: 'Time triggers',
            tooltip: "Automatically jump to certain time frames based on the viewers actions."
        },
        {
            id: 10,
            name: 'Subtitles',
            tooltip: "Add subtitles to the video player using SRT files so viewers can toggle subtitles on/off on your videos."
        },
        {
            id: 11,
            name: 'Customized player design',
            tooltip: "Brand the Qiwio video player with your brand logo and colors. Make our video player your own."
        },
        {
            id: 12,
            name: 'Data metrics dashboard',
            tooltip: "Detailed video performance metrics, allowing you to optimize your video based on results in real time."
        },
        {
            id: 13,
            name: 'Social media integrations',
            tooltip: "Share your videos to Facebook, LinkedIn and Youtube to capture more engagement from social media."
        },
        {
            id: 14,
            name: 'Zapier integration',
            tooltip: "Create custom integrations to your other tools with no code by using our Zapier integration. Zapier connects your Qiwio account to over 2,500 tools including your favourite eCommerce, sales or marketing tools."
        },
        {
            id: 15,
            name: 'Project assistance: Available to buy',
            tooltip: "Get expert help from the Qiwio creative team, allow us to jump on your project to help you push the boundaries of what's possible with interactive video and shoppable video and unleash the true power of the Qiwio studio platform."
        },
        {
            id: 16,
            name: '1:1 training sessions: Available to buy',
            tooltip: "Our customer success team will hold a training workshop for you and your team to teach you everything you need to know about the Qiwio interactive studio to help your team unleash the full power of interactive video."
        },
        {
            id: 17,
            name: 'Self service support',
            tooltip: "Gain access to our in-depth knowledge base to help you get the most out of Qiwio."
        },
        {
            id: 18,
            name: 'Standard customer support',
            tooltip: "Contact our support team via our ticketing system and get world class support via email."
        },
    ],
  },
  {
    name: 'growth',
    title: 'Growth',
    description: "For international eCommerce companies looking to grow their interactive shopping experience on a global scale",
    price: {
        annual: {
            sweden: '13 500 SEK',
            eur: '1 350 EUR'
        },
        monthly: {
            sweden: '15 000 SEK',
            eur: '1 500 EUR'
        }
    },
     tableFeatures: [
       {
            id: 1,
            name: 'Unlimited users',
            tooltip: 'Invite as many people as you wish to your Qiwio account and collaborate on your video projects. You could even invite your grandparents if you really wanted to! Not advised though.'
        },
        {
            id: 2,
            name: 'Unlimited interactive videos',
            tooltip: 'Create as many interactive videos as you want. No, really, as many as your heart desires.'
        },
        {
            id: 3,
            name: 'Unlimited interactive images',
            tooltip: 'Create as many interactive images as you want. No, really, as many as your heart desires.'
        },
        {
            id: 4,
            name: 'Full feature set',
            tooltip: 'The full range of interactive features at your mercy!'
        },
        {
            id: 5,
            name: 'Premium customer support',
            tooltip: "Recieve world-class priority customer support on weekdays via our support ticketing system, email or by phone."
        },
    ],
   detailFeatures:  [
        {
            id: 1,
            name: 'Unlimited users',
            tooltip: 'Invite as many people as you wish to your Qiwio account and collaborate on your video projects. You could even invite your grandparents if you really wanted to! Not advised though.'
        },
        {
            id: 2,
            name: 'Unlimited interactive videos',
            tooltip: 'Create as many interactive videos as you want. No, really, as many as your heart desires.'
        },
        {
            id: 3,
            name: 'Unlimited interactive images',
            tooltip: 'Create as many interactive images as you want. No, really, as many as your heart desires.'
        },
        {
            id: 4,
            name: 'Advanced interactive video editor',
            tooltip: "One of the world's most advanced interactive video editors at your fingertips. Can you handle that?"
        },
        {
            id: 5,
            name: 'Full interactive featureset',
            tooltip: "The full range of interactive features at your mercy!"
        },
        {
            id: 6,
            name: 'Hotspots',
            tooltip: "Hotspots that highlight products within the video for better conversions."
        },
        {
            id: 7,
            name: 'Custom hotspot creator',
            tooltip: "Custom hotspot creator allows you to upload your own SVG file and manipulate color, hover state and animations. Start to customize your Qiwio video however you wish."
        },
        {
            id: 8,
            name: 'Video funnels',
            tooltip: "Create truly interactive video experiences by changing which video is shown depending on the viewers interests for a unique experience and increase engagement and conversions through video."
        },
        {
            id: 9,
            name: 'Time triggers',
            tooltip: "Automatically jump to certain time frames based on the viewers actions."
        },
        {
            id: 10,
            name: 'Subtitles',
            tooltip: "Add subtitles to the video player using SRT files so viewers can toggle subtitles on/off on your videos."
        },
        {
            id: 11,
            name: 'Customized player design',
            tooltip: "Brand the Qiwio video player with your brand logo and colors. Make our video player your own."
        },
        {
            id: 12,
            name: 'Data metrics dashboard',
            tooltip: "Detailed video performance metrics, allowing you to optimize your video based on results in real time."
        },
        {
            id: 13,
            name: 'Social media integrations',
            tooltip: "Share your videos to Facebook, LinkedIn and Youtube to capture more engagement from social media."
        },
        {
            id: 14,
            name: 'Zapier integration',
            tooltip: "Connect Qiwio to over 2,500 tools including your favourite sales & marketing automation tools, for example, your CRM or email marketing tools to connect your video data with your sales and marketing teams."
        },
        {
            id: 15,
            name: 'Project assistance: Available to buy',
            tooltip: "Get expert help from the Qiwio creative team, allow us to jump on your project to help you push the boundaries of what's possible with interactive video and shoppable video and unleash the true power of the Qiwio studio platform."
        },
        {
            id: 16,
            name: '1x 1:1 training session',
            tooltip: "Our customer success team will hold a training workshop for you and your team to teach you everything you need to know about the Qiwio interactive studio to help your team unleash the full power of interactive video."
        },
        {
            id: 17,
            name: 'Product API',
            tooltip: "Connect your Qiwio account to your product management solution to automatically sync up to date product data and translations to Qiwio."
        },
        {
            id: 18,
            name: 'Product feeds',
            tooltip: "Display and manage your product data you sync using the Product API, all within Qiwio."
        },
        {
            id: 19,
            name: 'Automated product translations',
            tooltip: "Using the product API, automatically translate your product information to the local language of your viewers. Finally, no need to create seperate videos for each market. Just one interactive video ready for every market."
        },
        {
            id: 20,
            name: 'Geolocation',
            tooltip: "Automatially detect the location of your viewers and Qiwio will use the dynamic data synced via the Product API to display the correct language and currency for all product information overlays."
        },
        {
            id: 21,
            name: 'Live interactive elements',
            tooltip: "With Qiwio's live elements, you’re able to dynamically update interactive overlay content with real-time information straight from your product API. This completely removes the need to manually update ANY content that frequently or constantly changes."
        },
        {
            id: 22,
            name: 'Self service support',
            tooltip: "Gain access to our in-depth knowledge base to help you get the most out of Qiwio."
        },
        {
            id: 23,
            name: 'Premium customer support',
            tooltip: "Recieve world-class priority customer support on weekdays via our support ticketing system, email or by phone."
        },
    ],
  },
  {
    title: 'Enterprise',
    description: "For the big players looking to get the edge over their competition.",
    price: {
        annual: {
            sweden: "Let's chat",
            eur: "Let's chat"
        },
        monthly: {
            sweden: "Let's chat",
            eur: "Let's chat"
        }
    },
    tableFeatures: [
       {
            id: 1,
            name: 'Unlimited video projects',
            tooltip: 'Create as many interactive video projects as you want!'
        },
        {
            id: 2,
            name: '10+ users',
            tooltip: 'Invite people to your Qiwio account and collaborate on your video projects.'
        },
        {
            id: 3,
            name: '1 000 000+ monthly video views',
            tooltip: 'Predictable and scalable bandwidth for your brand.'
        },
        {
            id: 4,
            name: 'Advanced interactive video editor',
            tooltip: "One of the world's most advanced interactive video editors at your fingertips. Can you handle that?"
        },
        {
            id: 5,
            name: 'Dedicated customer account manager',
            tooltip: "Get your own Qiwio customer manager to guide you to video success."
        },
        {
            id: 6,
            name: 'Enterprise-grade technical support',
            tooltip: "Get instant technical support from Qiwio's tech team."
        },
        {
            id: 7,
            name: 'Creative support',
            tooltip: "Get personalized support from Qiwio's creative team to help you create world class interactive experiences."
        },
        {
            id: 8,
            name: 'SLA agreements',
            tooltip: "For unique enterprise solutions, SLA's can be agreed for your peace of mind."
        },
    ],
    detailFeatures:  [
        {
            id: 1,
            name: 'Unlimited video projects',
            tooltip: 'Create as many interactive video projects as you want!'
        },
        {
            id: 2,
            name: '10+ users',
            tooltip: 'Invite people to your Qiwio account and collaborate on your video projects.'
        },
        {
            id: 3,
            name: '1 000 000+ monthly video views',
            tooltip: 'Predictable and scalable bandwidth for your brand.'
        },
        {
            id: 4,
            name: 'Advanced interactive video editor',
            tooltip: "One of the world's most advanced interactive video editors at your fingertips. Can you handle that?"
        },
        {
            id: 5,
            name: 'Full interactive featureset',
            tooltip: "The full range of interactive features at your mercy!"
        },
        {
            id: 6,
            name: 'Hotspots',
            tooltip: "Hotspots that highlight products within the video for better conversions."
        },
        {
            id: 7,
            name: 'Video funnels',
            tooltip: "Create truly interactive video experiences by changing which video is shown depending on the viewers interests for a unique experience and increase engagement and conversions through video."
        },
        {
            id: 8,
            name: 'Time triggers',
            tooltip: "Automatically jump to certain time frames based on the viewers actions."
        },
        {
            id: 9,
            name: 'Subtitles',
            tooltip: "Add subtitles to the video player so viewers can toggle subtitles on/off on your videos."
        },
        {
            id: 10,
            name: 'Customized player design',
            tooltip: "Brand the Qiwio video player with your brand logo and colors. Make our video player your own."
        },
        {
            id: 11,
            name: 'Data metrics dashboard',
            tooltip: "Detailed video performance metrics, allowing you to optimize your video based on results in real time."
        },
        {
            id: 12,
            name: 'Social media integrations',
            tooltip: "Share your videos to Facebook, LinkedIn and Youtube to capture more engagement from social media."
        },
        {
            id: 13,
            name: 'Zapier integration',
            tooltip: "Connect Qiwio to over 2,500 tools including your favourite sales & marketing automation tools, for example, your CRM or email marketing tools to connect your video data with your sales and marketing teams."
        },
        {
            id: 14,
            name: 'Project assistance: Available to buy',
            tooltip: "Get expert help from the Qiwio creative team, allow us to jump on your project to help you push the boundaries of what's possible with interactive video and shoppable video and unleash the true power of the Qiwio studio platform."
        },
        {
            id: 15,
            name: '1:1 training sessions: Available to buy',
            tooltip: "Our customer success team will hold a training workshop for you and your team to teach you everything you need to know about the Qiwio interactive studio to help your team unleash the full power of interactive video."
        },
        {
            id: 16,
            name: 'Self service support',
            tooltip: "Gain access to our in-depth knowledge base to help you get the most out of Qiwio."
        },
        {
            id: 17,
            name: 'Email support',
            tooltip: "Contact our support team via our ticketing system and get world class support via email."
        },
        {
            id: 18,
            name: 'Dedicated customer account manager',
            tooltip: "Get your own Qiwio customer manager to guide you to video success."
        },
        {
            id: 19,
            name: 'Enterprise-grade technical support',
            tooltip: "Get instant technical support from Qiwio's tech team."
        },
        {
            id: 20,
            name: 'Creative support',
            tooltip: "Get personalized support from Qiwio's creative team to help you create world class interactive experiences."
        },
        {
            id: 21,
            name: 'SLA agreements',
            tooltip: "For unique enterprise solutions, SLA's can be agreed for your peace of mind."
        },
    ],
  },
];
