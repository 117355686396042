import React from "react";

import {
    EnterpriseTableCardWrapper,
    EnterpriseTableInfoWrapper,
    EnterpriseTableCardBtn,
    EnterpriseTableFeaturesWrapper
} from './enterprise-plan-card.styles'

import { BsCheck } from "@react-icons/all-files/bs/BsCheck"

const EnterprisePricingPlanCard = () => {
    return (
        <EnterpriseTableCardWrapper>
            <EnterpriseTableInfoWrapper>
                <h3>Enterprise</h3>
                <p>Do you have specific needs that require a more bespoke service? We would be happy to provide you with a tailored proposal.</p>
                <EnterpriseTableCardBtn to="/contact-sales">Contact us</EnterpriseTableCardBtn>
            </EnterpriseTableInfoWrapper>
            <EnterpriseTableFeaturesWrapper>
                <ul>
                    <li><BsCheck className="checkIcon" size={15}/>Custom plan<span>Every business is different. We will work with you to create a custom solution that best suits your needs.</span></li>
                    <li><BsCheck className="checkIcon" size={15}/>Dedicated customer account manager<span>Get your own Qiwio customer manager to guide you to video success.</span></li>
                    <li><BsCheck className="checkIcon" size={15}/>Enterprise-grade technical support<span>Get instant technical support from Qiwio's tech team.</span></li>
                    <li><BsCheck className="checkIcon" size={15}/>Advanced Integration support<span>Get step by step guidance from our tech team to build customer integrations using Qiwio's API's.</span></li>
                    <li><BsCheck className="checkIcon" size={15}/>Creative support<span>Get personalized support from Qiwio's creative team to help you create world class interactive experiences.</span></li>
                    <li><BsCheck className="checkIcon" size={15}/>SLA Agreements<span>For unique enterprise solutions, SLA's can be agreed for your peace of mind.</span></li>
                </ul>
            </EnterpriseTableFeaturesWrapper>
        </EnterpriseTableCardWrapper>
    )
}

export default EnterprisePricingPlanCard