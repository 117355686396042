import React from 'react';

import './pricing-tabs.scss'

import FirstTab from './pricing-tab-content/first-pricing-tab.component';

const PricingTabs = ({currency, setCurrency, prices, setInterval, interval}) => (
    <div className='pricing-tabs'>
        <div className='pricing-tab-nav-container'>
            <ul className='pricing-tab-nav'>
                <li
                    className={interval === 'year' ? 'active' : ''}
                    onClick={() => setInterval('year')}
                >
                    Yearly
                </li>
                <li
                    className={interval === 'month' ? 'active' : ''}
                    onClick={() => setInterval('month')}
                >
                    Monthly
                </li>
            </ul>
            <select
                name="subscription-currency"
                id="subscription-currency"
                onChange={setCurrency}
            >
                <option value="sek" selected>SEK</option>
                <option value="eur">EUR</option>
            </select>

        </div>

        <div className="outlet">
            <FirstTab prices={prices} currency={currency} />
        </div>
    </div>
)


export default PricingTabs