import styled from "styled-components";
import { Link } from "gatsby";

const QiwioPink = '#ff5679'

export const EnterpriseTableCardWrapper = styled.div`
    border: 1px solid white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 250px 30px 250px;
    padding: 20px;

    @media screen and (max-width: 1500px) {
        margin: 0 100px;
    }

    @media screen and (max-width: 1300px) {
        margin: 0 30px;
    }

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`
export const EnterpriseTableInfoWrapper = styled.div`
    h3 {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 20px;
    }
    p {
        font-size: 1rem;
        max-width: 80%;

        @media screen and (max-width: 1000px) {
        text-align: center;
        max-width: 100%;
    }
    }

    @media screen and (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
    }
`
export const EnterpriseTableCardBtn = styled(Link)`
    display: flex;
    justify-content: center;
    max-width: 40%;
    background-color: ${QiwioPink};
    padding: 10px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    font-family: 'barlow semi condensed';
    font-size: 1.125rem;
    font-weight: 600;
    margin-top: 20px;
    transition: all .4s;

    &:hover {
        transform: translateY(-10px);
    }

    @media screen and (max-width: 1000px) {
        padding: 10px 80px;
        max-width: 100%;
        text-align: center;
    }
`
export const EnterpriseTableFeaturesWrapper = styled.div`
    margin-right: 100px;
    min-width: max-content;

    @media screen and (max-width: 1000px) {
        margin-right: 0;
    }

    @media screen and (max-width: 500px) {
        min-width: 100%;
    }

    ul {
        list-style: none;
        color: white;
        font-family: 'barlow';

        li:not(:last-child) {
            margin-bottom: 10px;
        }

        li {
            position: relative;
            display: flex;
            align-items: center;

            span {
                visibility: hidden;
                width: 280px;
                background-color: #272731;
                color: white;
                border-radius: 6px;
                padding: 15px 20px;
                font-size: .875rem;
                font-weight: 300;
                line-height: 1.5rem;
                position: absolute;
                z-index: 1;
                top: 30px;
                right: -10px;
                margin-left: -100%;
            }

            span:after {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -5px;
            }

            &:hover span {
                visibility: visible;
            }
        }
    }
`