import React from "react";

import {
    FeaturesTableContainer,
    ScrollWrapper,
    FeaturesTable,
    NotIncludedTd,
    Seperator
} from './pricing-features-table.styles'

const PricingFeaturesTable = () => {
    return (
        <FeaturesTableContainer>
            <ScrollWrapper>
                <h3>Scroll &rarr;</h3>
            </ScrollWrapper>
            <FeaturesTable>
                <tbody>
                    <tr>
                        <td>&nbsp;</td>
                        <td><p><strong>Free</strong><sup>i</sup><span>For curious Ecommerce companies wanting to test an interactive shopping experience</span></p></td>
                        <td><p><strong>Standard</strong><sup>i</sup><span>For eCommerce companies getting started with interactive video shopping experiences</span></p></td>
                        <td><p><strong>Plus</strong><sup>i</sup><span>For growing eCommerce companies looking to harness the power of interactive video shopping</span></p></td>
                        <td><p><strong>Growth</strong><sup>i</sup><span>For international eCommerce companies looking to grow their interactive shopping experience on a global scale</span></p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Price</strong></p></td>
                        <td><p>Free</p></td>
                        <td><p>900 SEK/mo billed yearly</p></td>
                        <td><p>4 500 SEK/mo billed yearly</p></td>
                        <td><p>13 500 SEK/mo billed yearly</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Monthly View Limit</strong><sup>i</sup><span>The number of monthly views your Qiwio account is limited to.</span></p></td>
                        <td><p>1 000 views</p></td>
                        <td><p>Unlimited</p></td>
                        <td><p>Unlimited</p></td>
                        <td><p>Unlimited</p></td>
                    </tr>
                    <tr>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <td><p><strong>Max Users</strong></p></td>
                        <td><p>1</p></td>
                        <td><p>3</p></td>
                        <td><p>10</p></td>
                        <td><p>Unlimited</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Max Interactive Videos</strong><sup>i</sup><span>Total number of interactive videos you can store in your Qiwio account at any given time.</span></p></td>
                        <td><p>2</p></td>
                        <td><p>2</p></td>
                        <td><p>Unlimited</p></td>
                        <td><p>Unlimited</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Max Interactive Images</strong><sup>i</sup><span>Total number of interactive images you can store in your Qiwio account at any given time.</span></p></td>
                        <td><p>10</p></td>
                        <td><p>10</p></td>
                        <td><p>Unlimited</p></td>
                        <td><p>Unlimited</p></td>
                    </tr>
                    <tr>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <td><p><strong>Featureset</strong></p></td>
                        <td><p>Standard</p></td>
                        <td><p>Standard</p></td>
                        <td><p>Plus</p></td>
                        <td><p>Full</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>Hotspots</strong><sup>i</sup><span>Hotspots highlight products within the video for better conversions.</span></p></td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Custom Hotspot Creator</strong><sup>i</sup><span>Custom hotspot creator allows you to upload your own SVG file and manipulate color, hover state and animations. Start to customize your Qiwio video however you wish.</span></p></td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Video Funnels</strong><sup>i</sup><span>Create truly interactive video experiences by changing which video is shown depending on the viewers interests for a unique experience and increase engagement and conversions through video.</span></p></td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Time Triggers</strong><sup>i</sup><span>Automatically jump to certain time frames based on the viewers actions.</span></p></td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Subtitles</strong><sup>i</sup><span>Add subtitles to the video player using SRT files so viewers can toggle subtitles on/off on your videos.</span></p></td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Customized Player Design</strong><sup>i</sup><span>Brand the Qiwio video player with your brand logo and colors. Make our video player your own.</span></p></td>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <td><p><strong>Data Metrics Dashboard</strong><sup>i</sup><span>Detailed video performance metrics, allowing you to optimize your video based on results in real time.</span></p></td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <td><p><strong>Zapier Integration</strong><sup>i</sup><span>Connect Qiwio to over 2,500 tools including your favourite sales & marketing automation tools, for example, your CRM or email marketing tools to connect your video data with your sales and marketing teams.</span></p></td>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <td>✔</td>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <td><p><strong>Project Assistance</strong><sup>i</sup><span>Get expert help from the Qiwio creative team, allow us to jump on your project to help you push the boundaries of what's possible with interactive video and shoppable video and unleash the true power of the Qiwio studio platform.</span></p></td>
                        <td><p>Available to buy</p></td>
                        <td><p>Available to buy</p></td>
                        <td><p>Available to buy</p></td>
                        <td><p>Available to buy</p></td>
                    </tr>
                    <tr>
                        <td><p><strong>1:1 Training Sessions</strong><sup>i</sup><span>Our customer success team will hold a training workshop for you and your team to teach you everything you need to know about the Qiwio interactive studio to help your team unleash the full power of interactive video.</span></p></td>
                        <td><p>Available to buy</p></td>
                        <td><p>Available to buy</p></td>
                        <td><p>Available to buy</p></td>
                        <td><p>1x 1:1 training session</p></td>
                    </tr>
                    <tr>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <td><p><strong>Product API</strong><sup>i</sup><span>Connect your Qiwio account to your product management solution to automatically sync up to date product data and translations to Qiwio.</span></p></td>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Product Feeds</strong><sup>i</sup><span>Display and manage your product data you sync using the Product API, all within Qiwio.</span></p></td>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Automated Product Translations</strong><sup>i</sup><span>Using the product API, automatically translate your product information to the local language of your viewers. Finally, no need to create seperate videos for each market. Just one interactive video ready for every market.</span></p></td>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Geolocation</strong><sup>i</sup><span>Automatially detect the location of your viewers and Qiwio will use the dynamic data synced via the Product API to display the correct language and currency for all product information overlays.</span></p></td>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Live interactive elements</strong><sup>i</sup><span>With Qiwio's live elements, you’re able to dynamically update interactive overlay content with real-time information straight from your product API. This completely removes the need to manually update ANY content that frequently or constantly changes.</span></p></td>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <td><p><strong>Self Service Support</strong><sup>i</sup><span>Gain access to our in-depth knowledge base to help you get the most out of Qiwio.</span></p></td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                        <td>✔</td>
                    </tr>
                    <tr>
                        <td><p><strong>Customer Support</strong><sup>i</sup></p><span>Recieve world-class customer support on weekdays via our support ticketing system, email or by phone.</span></td>
                        <NotIncludedTd>✗</NotIncludedTd>
                        <td><p>Standard</p></td>
                        <td><p>Standard</p></td>
                        <td><p>Premium</p></td>
                    </tr>
                    <tr>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                        <Seperator>&nbsp;</Seperator>
                    </tr>
                </tbody>
            </FeaturesTable>
        </FeaturesTableContainer>
    )
}

export default PricingFeaturesTable