import React from "react";

import {
    PricingTableCardWrapper,
    PricingTableBtn
} from './pricing-table-card.styles'

import './pricing-table-card.scss'

const PricingTableCard = ({title, description, price, frequency, features, currency}) => (
    <PricingTableCardWrapper>
        <h3>{title}</h3>
        <p>{description}</p>

        <h2>{price === 'contact' ? 'Contact us' : <>{price.toLocaleString('en-SE')} {currency}<span>/mo</span></>}</h2>
        {/* <h2><span>/mo</span></h2> */}
        <p>{frequency}</p>


        <PricingTableBtn to="/free-trial">Start your free trial</PricingTableBtn>

        <ul>{features}</ul>
    </PricingTableCardWrapper>
)


export default PricingTableCard