import React from 'react'

import {
    PricingServicesCardWrapper
} from './pricing-services-card.styles'

export default function PricingServicesCard({title, description}) {
  return (
    <PricingServicesCardWrapper>
        <h3>{title}</h3>
        <h5>Custom Pricing</h5>

        <p>{description}</p>
    </PricingServicesCardWrapper>
  )
}
