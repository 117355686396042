import styled from "styled-components";
import { Link } from "gatsby";

const QiwioPink = '#ff5679'

export const PricingTableCardWrapper = styled.div`
    min-width: 32.3%;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 10px;
    padding: 20px 20px 80px 20px;
    margin-bottom: 10px;

    @media screen and (min-width: 701px) {
        max-width: 49.5%;

        &:nth-child(odd) {
            margin-right: 1%;
        }
    }

    @media screen and (min-width: 1100px) {
        max-width: 24.2%;

        &:not(:last-child) {
            margin-right: 1%;
        }
    }

    h3 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 10px;
    }
    p {
        font-family: 'barlow';
        font-size: 1rem;
        margin-bottom: 30px;
    }
    h2 {
        font-size: 3rem;
        font-weight: 600;

        span {
            font-size: 1.25rem;
            font-weight: 300;
        }
    }
    ul {
        list-style: none;
        color: white;
        margin-top: 30px;
        font-family: 'barlow';

        li:not(:last-child) {
            margin-bottom: 10px;
        }

        li {
            position: relative;
            display: flex;
            align-items: center;

            span {
                visibility: hidden;
                width: 280px;
                background-color: #272731;
                color: white;
                border-radius: 6px;
                padding: 15px 20px;
                font-size: .875rem;
                font-weight: 300;
                line-height: 1.5rem;
                position: absolute;
                z-index: 1;
                top: 30px;
                right: -10px;
                margin-left: -100%;
            }

            span:after {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -5px;
            }

            &:hover span {
                visibility: visible;
            }
        }
    }
`
export const PricingTableBtn = styled(Link)`
    color: white;
    background-color: ${QiwioPink};
    display: block;
    text-align: center;
    text-decoration: none;
    border-radius: 6px;
    padding: 15px 0;
    font-family: 'barlow semi condensed';
    font-size: 1.125rem;
    font-weight: 600;
    cursor: pointer;
    transition: all .4s;

    &:hover {
        transform: translateY(-4px)
    }
`