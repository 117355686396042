import styled from "styled-components";

export const TabContainer = styled.div`
    padding: 30px 0;
    overflow: hidden;

    @media screen and (min-width: 701px) {
        display: flex;
        flex-wrap: wrap;
    }
`