export const accordionPricingData = [
  {
    title: 'Do I have to worry about you selling my email or data?',
    content: "No. Your contact information and private data are yours and yours alone. They’re safe with us. We’ll email you about product and company updates, but you can opt out any time. Learn more about our Privacy Policy."
  },
  {
    title: 'How will I be charged on the paid plans?',
    content: "The plans are charged with 2 seperate transactions (SaaS and Usage). The first transaction is for the SaaS subscription and is charged at the start of your billing period. The second transaction is for your usage (how many views your content recieves during a billing period) and is charged at the end of your billing period."
  },
  {
    title: 'Do I have to pay yearly?',
    content: "Nope! Payments are made monthly (or yearly) and you can cancel, upgrade, or downgrade at any time!"
  },
];