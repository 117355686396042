import React from "react";

import { pricingData } from "../../pricing-table-card/pricing-table-data";
import PricingTableCard from "../../pricing-table-card/pricing-table-card.component";

import {
    TabContainer
} from './pricing-tabs.styles'

import { BsCheck } from "@react-icons/all-files/bs/BsCheck"

const FirstTab = ({prices, currency}) => (
    <TabContainer>
        {pricingData.slice(1,4).map(({name, title, description, tableFeatures}) => (
            <PricingTableCard
                title={title}
                key={title}
                description={description}
                price={prices?.[name]}
                currency={currency}
                // frequency={title === 'Enterprise' ? <br /> : 'Billed yearly'}
                features={tableFeatures.map((item) => (
                    <li key={item.id}><BsCheck className="checkIcon" size={15}/>{item.name} <span>{item.tooltip}</span></li>
                ))}
            />
        ))} 
    </TabContainer>
)

export default FirstTab

