import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Layout from "../components/theme/layout";
import Seo from "../components/seo";

import PricingServicesCard from "../components/pricing/pricing-services-card/pricing-services-card.component";
import { servicesData } from "../components/pricing/pricing-services-card/pricing-services-data";
import PricingTabs from "../components/pricing/pricing-table-tab/pricing-tabs.component";
import Accordion from '../components/accordion/accordion.component'
import {accordionPricingData} from '../components/accordion/pricing-data'
import CtaSection from "../components/cta-section/cta-section.component";
import NewsletterSection from "../components/newsletter/newsletter.component";
import PricingFeaturesTable from "../components/pricing/pricing-features-table/pricing-features-table.component";
import EnterprisePricingPlanCard from "../components/pricing/enterprise-plan-card/enterprise-plan-card.component";



// Defining page styles
const QiwioPink = '#ff5679'

const HeroWrapper = styled.div `
    margin: 100px 0 50px 0;

    @media screen and (max-width: 1300px) {
        margin: 100px 30px 50px 30px;
    }
    @media screen and (max-width: 500px) {
        margin: 100px 10px 50px 10px;
    }

    h1 {
        font-size: 3rem;
        font-weight: 800;
        text-align: center;

        @media screen and (max-width: 600px) {
            font-size: 2.25rem;
        }
    }
`
const FreePlanWrapper = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid white;
    border-radius: 6px;
    width: max-content;
    margin: 0 auto;
    padding: 10px 20px;

    @media screen and (max-width: 1100px) {
        width: 80%;
    }
    @media screen and (max-width: 650px) {
        flex-direction: column;
        text-align: center;
    }
    @media screen and (max-width: 600px) {
        width: 78%;
    }

    h6 {
        font-weight: 400;
    }

    a {
        margin-left: 10px;
        font-weight: 600;
        color: ${QiwioPink};

        &:hover {
            color: white;
        }

        @media screen and (max-width: 1100px) {
            text-align: center;
        }
        @media screen and (max-width: 650px) {
            margin: 10px 0 0 0;
        }
    }
`
const PricingTableWrapper = styled.div`
    padding: 0;

    @media screen and (min-width: 500px) {
        padding: 0 30px;
    }

    @media screen and (min-width: 900px) {
        padding: 0 50px;
    }

    @media screen and (min-width: 2100px) {
        padding: 0 200px;
    }
`
const PricingSliderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    position: relative;
    margin-bottom: 30px;


    @media screen and (min-width: 500px) {
        padding: 100px 30px 0 30px;
    }

    @media screen and (min-width: 900px) {
        padding: 100px 50px 0 50px;
    }

    @media screen and (min-width: 2100px) {
        padding: 100px 200px 0 200px;
    }

    @media screen and (min-width: 2600px) {
        max-width: 80%;
        margin: 0 auto;
    }
`
const PricingSliderOutputValue = styled.output`
    text-align: center;
    position: absolute;
    min-width: 200px;
    top: -50px;
    left: 0;
    transform:translateX(-50%);
    font-size: 14px;
    --thumb-size: 36px;

    @media screen and (max-width: 500px) {
        top: 50px
    }

    h5 {
        font-size: 1rem;
    }
    p {
        font-size: 0.75rem;
    }
`
const PricingSlider = styled.div`
    position: relative;

    /* Slider Bar */
    input[type=range] {
        -webkit-appearance: none;
        margin: 18px 0;
        width: 700px;

        background: transparent;

        @media screen and (max-width: 500px) {
            margin-top: 120px;
        }
        @media screen and (max-width: 800px) {
            width: 80vw;
        }
    }
    input[type=range]:focus {
        outline: none;
    }
    input[type=range]::-webkit-slider-runnable-track {
        width: 700px;
        height: 8.4px;
        cursor: pointer;
        background: white;
        border-radius: 100px;
    }
    input[type=range]:focus::-webkit-slider-runnable-track {
        background: white;
    }
    input[type=range]::-moz-range-track {
        width: 700px;
        height: 8.4px;
        cursor: pointer;
        background: white;
        border-radius: 100px !important;
    }
    input[type=range]::-ms-track {
        width: 700px;
        height: 8.4px;
        cursor: pointer;
        background: white;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
    }
    input[type=range]::-ms-fill-lower {
        background: white;
        border-radius: 100px;
    }
    input[type=range]::-ms-fill-upper {
        background: white;
        border-radius: 100px;
    }
    input[type=range]:focus::-ms-fill-lower {
        background: white;
    }
    input[type=range]:focus::-ms-fill-upper {
        background: white;
    }

    /* Slider Thumb / Circle */
    input[type=range]::-webkit-slider-thumb {
        height: 36px;
        width: 36px;
        box-shadow: 0 3px 16px rgb(55 64 64 / 25%);
        border-radius: 100px;
        background: #ff5679;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -14px;
    }
    input[type=range]::-moz-range-thumb {
        box-shadow: 0 3px 16px rgb(55 64 64 / 25%);
        height: 36px;
        width: 36px;
        border-radius: 100px;
        background: #ff5679;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        border: none;
    }
    input[type=range]::-ms-thumb {
        box-shadow: 0 3px 16px rgb(55 64 64 / 25%);
        height: 36px;
        width: 36px;
        border-radius: 100px;
        background: #ff5679;
        background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23FFFFFF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
`
const ServicesCardWrapper = styled.div`
    margin: 0 250px;
    padding: 30px 0;

    display: flex;
    justify-content: center;

    @media screen and (max-width: 1500px) {
        margin: 0 100px;
    }

    @media screen and (max-width: 1300px) {
        margin: 0 30px;
    }

    @media screen and (max-width: 1100px) {
        flex-direction: column;
    }
`
const PricingDetailsWrapper = styled.div`
    padding: 200px 50px 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 2000px) {
        max-width: 80%;
        margin: 0 auto;
    }

    h2 {
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;

        @media screen and (max-width: 600px) {
            font-size: 2.25rem;
        }
    }
`
const FaqWrapper = styled.div`
    padding: 100px 30px;

    @media screen and (min-width: 900px) {
        padding: 100px 50px;
    }

    h3 {
        font-size: 2.3rem;
        text-align: center;
        margin-bottom: 30px;
    }
`

class Pricing extends React.Component {
    state = {
        usage: 0,
        usageRange: [
            ...['< 10 000'],
            ...new Array(100).fill(null).map((item, i) => i === 99 ? '1 000 000 +': `${i+1}0 000`)
        ],

        prices: {
            month: {
                eur: {
                    standard: 100,
                    plus: 500,
                    growth: 1500,
                },
                sek: {
                    standard: 1000,
                    plus: 5000,
                    growth: 15000,
                },
                usage: [
                    {level: 100000, min: 1, max: 100000, eur: 50, sek: 500},
                    {level: 400000, min: 100001, max: 500000, eur: 40, sek: 400},
                    {level: 500000, min: 500001, max: 1000000, eur: 20, sek: 200},
                ],
            },
            year: {
                eur: {
                    standard: 90,
                    plus: 450,
                    growth: 1350,
                },
                sek: {
                    standard: 900,
                    plus: 4500,
                    growth: 13500,
                },
                usage: [
                    {level: 100000, min: 1, max: 100000, eur: 45, sek: 450},
                    {level: 400000, min: 100001, max: 500000, eur: 36, sek: 360},
                    {level: 500000, min: 500001, max: 1000000, eur: 18, sek: 180},
                ],
            }
        },
        currency: 'sek',
        interval: 'year',
    }
    // Create ref
    slider = React.createRef()
    sliderValue = React.createRef()

    // Setting range slider min/max attributes
    componentDidMount() {
        // Getting the slider thumb size from a CSS Property
        this.thumbSize = parseInt(
        window
            .getComputedStyle(this.sliderValue.current)
            .getPropertyValue("--thumb-size"),
        10
        );
    }

    calculatePrice = () => {
        const plans = {...this.state.prices[this.state.interval][this.state.currency]};
        if (0 === this.state.usage) return plans;

        let usage = this.state.usage * 10000;
        const levels = this.state.prices[this.state.interval].usage;
        const usageLevel = levels.filter(u => u.min < usage);

        if (levels[levels.length-1].max === usage) {
            return {
                standard: 'contact',
                plus: 'contact',
                growth: 'contact',
            }
        }

        let cost = 0;
        usageLevel.forEach(item => {
            if (usage <= 0) {
                return;
            }

            cost += (usage >= item.level ? item.level : usage) / 10000 * item[this.state.currency];
            usage -= item.level;
        });

        Object.keys(plans).forEach(key => {
            plans[key] += cost;
        });

        return plans;
    }


    handleUsage = e => {
        const usage = e.target.value;
        this.setState({ usage: usage});
        const range = e.target;

        const newValue = Number((usage - range.min) * 100 / (range.max - range.min)),
            newPosition = 10 - (newValue * 0.2);

        this.sliderValue.current.style.left = `calc(${newValue}% + (${newPosition}px))`;
    }

    setCurrency = e => {
        this.setState({currency: e.target.value});
    }
    setInterval = interval => {
        console.log(interval)
        this.setState({interval: interval});
    }

    render() {
        return (
        <Layout>
            <Seo title='Qiwio pricing plans: Grow your business with interactive video' />

            <HeroWrapper>
                <h1>Usage-based plans for brands of all sizes</h1>
            </HeroWrapper>

            <FreePlanWrapper>
                <h6>Start your unlimited 14-day free trial. After your trial expires, choose to upgrade, or continue with a free plan.</h6>
                <Link to="/free-trial"> Start your free trial</Link>
            </FreePlanWrapper>

            <PricingTableWrapper>
                <PricingSliderWrapper>
                    <PricingSlider>
                        <PricingSliderOutputValue ref={this.sliderValue}>
                            <h5>{this.state.usageRange[this.state.usage]}</h5>
                            <p>Video views /month</p>
                        </PricingSliderOutputValue>
                        <label htmlFor="">
                            <input
                                type="range"
                                defaultValue={this.state.usage}
                                ref={this.slider}
                                onChange={this.handleUsage}
                                max={this.state.usageRange.length - 1}
                                min={0}
                                name="priceSlider"
                            />
                        </label>
                    </PricingSlider>
                </PricingSliderWrapper>

                <PricingTabs
                    prices={this.calculatePrice(this.state.usage)}
                    setCurrency={this.setCurrency}
                    currency={this.state.currency}
                    interval={this.state.interval}
                    setInterval={this.setInterval}
                />

                <EnterprisePricingPlanCard />

                <ServicesCardWrapper>
                    {servicesData.map(({title, description}, i) => (
                        <PricingServicesCard key={i} title={title} description={description} />
                    ))}
                </ServicesCardWrapper>
            </PricingTableWrapper>

            <PricingDetailsWrapper>
                <h2>Compare our plans</h2>

                {/*
                <DetailsCardContainer>
                    {pricingData.slice(0,4).map(({title, price, detailFeatures}) => (
                        <PricingDetailsCard
                            title={title}
                            price={price.annual.sweden}
                            features={detailFeatures.map((item) => (
                                <li key={item.id}>{item.name}<span>{item.tooltip}</span></li>
                            ))}

                        />
                    ))}
                </DetailsCardContainer>
                */}

                <PricingFeaturesTable />
            </PricingDetailsWrapper>

            <FaqWrapper>
                <h3>Frequently Asked Questions</h3>
                 <div className="accordion">
                    {accordionPricingData.map(({ title, content }) => (
                    <Accordion key={title} title={title} content={content} />
                    ))}
                </div>
            </FaqWrapper>

            <CtaSection />
            <NewsletterSection />
        </Layout>
    )}
}
export default Pricing