import styled from "styled-components";

const QiwioPink = '#ff5679'

export const FeaturesTableContainer = styled.div`
    width: 100%;

`
export const ScrollWrapper = styled.div `
    text-align: right;
    margin-top: 50px;
    font-family: 'Menlo' !important;

    @media screen and (min-width: 1083px) {
        display: none;
    }
`
export const FeaturesTable = styled.table`
    margin: 50px auto 0 auto;
    border-collapse: collapse;

    @media screen and (max-width: 1083px) {
        margin: 20px auto 0 auto;
        display: block;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
    }

    td {
        border: 2px solid white;
        text-align: center;
        padding: 5px 20px;
        vertical-align: middle;
        width: 260px;
        color: ${QiwioPink};
        font-size: 1.25rem;
        position: relative;

        span {
            visibility: hidden;
            width: 280px;
            background-color: #272731;
            color: white;
            border-radius: 6px;
            padding: 15px 20px;
            font-size: .75rem;
            font-weight: 300;
            line-height: 1.2rem;
            position: absolute;
            z-index: 1;
            top: 35px;
            right: 0%;
            margin-left: -100%;
            white-space: pre-wrap;

            @media screen and (max-width: 1400px) {
                right: -25px;
            }
        }

        span:after {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
        }
        &:hover span {
            visibility: visible;
        }
    }

    p {
        font-size: 0.875rem;

        sup {
            padding-left: 5px;
            font-family: poppins;
            font-size: 0.6rem;
        }
    }
`
export const NotIncludedTd = styled.td`
    color: white !important;
    font-size: 0.875rem !important;
`
export const Seperator = styled.td`
    border: none !important;
`