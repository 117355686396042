import styled from "styled-components";

export const PricingServicesCardWrapper = styled.div`
    background-color: #272731;
    color: white;
    padding: 30px;
    border-radius: 10px;
    transition: all .4s;
    margin-bottom: 10px;

    @media screen and (min-width: 1100px) {
        max-width: 49.5%;

        &:not(:last-child) {
            margin-right: 1%;
        }
    }

    &:hover {
        transform: translateY(-4px)
    }

    h3 {
        font-size: 1.875rem;
        font-weight: 600;
        margin-bottom: 10px;
    }
    h5 {
        font-size: 1.25rem;
        font-family: 'Barlow Semi Condensed';
        margin-bottom: 10px;
    }
    p {
        font-size: 1rem;
    }
`